var site = site || {};

(function ($, Drupal) {
  'use strict';

  /**
  * Site My Shades v1
  */
  var siteMyShadesV1 = {
    formatter: $('.site-my-shades-v1'),
    cookie_name: 'mac_shades',
    signed_in: typeof site.userInfoCookie == 'undefined' ? 0 : site.userInfoCookie.getValue('signed_in'),
    default_shades: ['#000000', '#000000', '#000000']
  };

  Drupal.behaviors.siteMyShadesV1 = {
    get: function (key) {
      return siteMyShadesV1[key];
    },

    set: function (key, val) {
      siteMyShadesV1[key] = val;
    },

    setGnavMyShades: function (self, thisFormatter) {
      var shades = self.getShades(self);
      var cookie_name = self.get('cookie_name');
      var signed_in = self.get('signed_in');
      // get the number of available shades so we can set the CSS accordingly
      // if there are less than 3 shades we move the height of the container to account for it
      var shadesLength = shades.length;
      var numShades = shadesLength <= 3 ? shadesLength : 3;

      thisFormatter.find('.my-shades').attr('data-my-shades', numShades);

      thisFormatter.find('.site-my-shades--1').css('background-color', shades[0]);
      thisFormatter.find('.site-my-shades--2').css('background-color', shades[1]);
      thisFormatter.find('.site-my-shades--3').css('background-color', shades[2]);
    },

    setShades: function (self, shade, action) {
      var shades = self.getShades(self);

      if (action == 'add') {
        shades.unshift(shade);
      } else {
        var newShades = [];

        // loop through all the shades and build a new array containing new shades since they removed one
        for (var index in shades) {
          if (shades[index] != shade) {
            newShades.push(shades[index]);
          }
        }
        shades = newShades;
      }

      var encodedShades = [];

      $(shades).each(function () {
        encodedShades.push(encodeURIComponent(this));
      });
      $.cookie('mac_shades', encodedShades.join(','), { path: '/' });

      return shades;
    },

    getShades: function (self) {
      var shades;
      var signed_in = self.get('signed_in');
      var cookie = decodeURIComponent($.cookie('mac_shades'));

      shades = cookie.split(',');

      return shades;
    },

    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);

        // gnavdata.js will trigger 'gnavdata_success'
        $(window).on('gnavdata_success', function (e, results) {
          // Note: Bloom moved this here from gnavdata.js. Event now sends over
          // its payload of data
          var shades = [];

          if (!_.isEmpty(results)) {
            $(results.SHADES).each(function () {
              shades.push(encodeURIComponent(this));
            });
          }
          $.cookie('mac_shades', shades.join(','), { path: '/' });
        });

        // Handle the scenario where the gnav success is fired before the listener
        // is listening
        //
        // Note: commented out by Bloom because gnavdata has been moved below
        // all template_api js so all listeners are now set before gnavdata can fire
        // and we also don't want to fire this event repeatedly since so many
        // things listen for it.
        //
        // _.debounce( 1, $(window).trigger('gnavdata_success') );

        // user added/removed a favorite so remove it from the queue and update the gnav
        $(window).on('favorite_action', function (e, hexValue, action) {
          self.setShades(self, hexValue, action);
        });
      });
    },

    attached: false
  };
})(jQuery, Drupal);
